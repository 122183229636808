/**
* Template Name: Day - v2.2.0
* Template URL: https://bootstrapmade.com/day-multipurpose-html-template-for-free/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: "Open Sans", sans-serif;
  color: #444444;
}

a {
  color: #f89406;
}

a:hover {
  color: #f89406;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  display: none;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
}

.back-to-top i {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  width: 40px;
  height: 40px;
  background: #f89406;
  color: #fff;
  transition: all 0.4s;
}

.back-to-top i:hover {
  background: #e72323;
  color: #fff;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #f89406;
  border-top-color: #efefef;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Top Bar
--------------------------------------------------------------*/
#topbar {
  height: 40px;
  font-size: 14px;
  transition: all 0.5s;
  color: rgba(255, 255, 255, 0.6);
}

#topbar.topbar-scrolled {
  top: -40px;
}

#topbar .contact-info a {
  line-height: 1;
  color: rgba(255, 255, 255, 0.6);
  transition: 0.3s;
}

#topbar .contact-info a:hover {
  color: #fff;
}

#topbar .contact-info i {
  color: #f89406;
  padding-right: 4px;
}

#topbar .contact-info i.icofont-phone {
  margin-left: 15px;
}

#topbar .social-links a {
  color: rgba(255, 255, 255, 0.5);
  padding-left: 15px;
  display: inline-block;
  line-height: 1px;
  transition: 0.3s;
}

#topbar .social-links a:hover {
  color: #fff;
}

#topbar .social-links a:first-child {
  border-left: 0;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  background: rgba(52, 58, 64, 0.2);
  transition: all 0.5s;
  z-index: 997;
  top: 40px;
}

#header.header-scrolled {
  top: 0;
  background: rgba(0, 0, 0, 0.9);
}

#header .logo {
  font-size: 32px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
}

#header .logo a {
  color: #fff;
}

#header .logo img {
  max-height: 40px;
}

@media (max-width: 992px) {
  #header {
    top: 0;
    padding: 15px 0;
    background: transparent;
  }

  #header .logo {
    font-size: 28px;
  }
}

.header-inner-pages {
  background: rgba(0, 0, 0, 0.9) !important;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Desktop Navigation */
.nav-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu>ul {
  display: flex;
}

.nav-menu>ul>li {
  position: relative;
  white-space: nowrap;
  padding: 18px 15px;
  margin-left: 5px;
  transition: 0.3s;
}

.nav-menu a {
  display: block;
  position: relative;

  transition: 0.3s;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
}

.nav-menu>ul>li:hover,
.nav-menu .active {
  background: #f89406;
}

.nav-menu .drop-down ul {
  display: block;
  position: absolute;
  left: 0;
  top: calc(100% + 30px);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  padding: 10px 0;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.nav-menu .drop-down:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.nav-menu .drop-down li {
  min-width: 180px;
  position: relative;
}

.nav-menu .drop-down ul a {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  color: #191919;
}

.nav-menu .drop-down ul a:hover,
.nav-menu .drop-down ul .active>a,
.nav-menu .drop-down ul li:hover>a {
  color: #f89406;
}

.nav-menu .drop-down>a:after {
  content: "\ea99";
  font-family: IcoFont;
  padding-left: 5px;
}

.nav-menu .drop-down .drop-down ul {
  top: 0;
  left: calc(100% - 30px);
}

.nav-menu .drop-down .drop-down:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
}

.nav-menu .drop-down .drop-down>a {
  padding-right: 35px;
}

.nav-menu .drop-down .drop-down>a:after {
  content: "\eaa0";
  font-family: IcoFont;
  position: absolute;
  right: 15px;
}

@media (max-width: 1366px) {
  .nav-menu .drop-down .drop-down ul {
    left: -90%;
  }

  .nav-menu .drop-down .drop-down:hover>ul {
    left: -100%;
  }

  .nav-menu .drop-down .drop-down>a:after {
    content: "\ea9d";
  }
}

/* Mobile Navigation */
.mobile-nav-toggle {
  position: fixed;
  right: 15px;
  top: 15px;
  z-index: 9998;
  border: 0;
  background: none;
  font-size: 24px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 1;
  cursor: pointer;
  text-align: right;
}

.mobile-nav-toggle i {
  color: #fff;
}

.mobile-nav {
  position: fixed;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  z-index: 9999;
  overflow-y: auto;
  background: #fff;
  transition: ease-in-out 0.2s;
  opacity: 0;
  visibility: hidden;
  border-radius: 10px;
  padding: 10px 0;
}

.mobile-nav * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mobile-nav a {
  display: block;
  position: relative;
  color: #191919;
  padding: 10px 20px;
  font-weight: 500;
  outline: none;
}

.mobile-nav a:hover,
.mobile-nav .active>a,
.mobile-nav li:hover>a {
  color: #f89406;
  text-decoration: none;
}

.mobile-nav .drop-down>a:after {
  content: "\ea99";
  font-family: IcoFont;
  padding-left: 10px;
  position: absolute;
  right: 15px;
}

.mobile-nav .active.drop-down>a:after {
  content: "\eaa1";
}

.mobile-nav .drop-down>a {
  padding-right: 35px;
}

.mobile-nav .drop-down ul {
  display: none;
  overflow: hidden;
}

.mobile-nav .drop-down li {
  padding-left: 20px;
}

.mobile-nav-overly {
  width: 100%;
  height: 100%;
  z-index: 9997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  overflow: hidden;
  display: none;
  transition: ease-in-out 0.2s;
}

.mobile-nav-active {
  overflow: hidden;
}

.mobile-nav-active .mobile-nav {
  opacity: 1;
  visibility: visible;
}

.mobile-nav-active .mobile-nav-toggle i {
  color: #fff;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
/*
 #hero {
  width: 100%;
  height: 100vh;
  background: url("../img/Banner_2.jpg") top center;
  background-size: cover;
  position: relative;
} */
#hero {
  position: relative;
}
#hero video {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  opacity: 0.7;
}

#hero:before {
  content: "";
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hero .container {
  padding-top: 70px;
  text-align: center;
}

#hero h1 {
  color: #eee;
  margin: 0 0 10px 0;
  font-size: 42px;
  font-weight: 600;
}

#hero img {
  width: 45vw;
}

#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

#hero h2 {

  font-size: 56px;
  font-weight: 700;
  line-height: 56px;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 30px;
}

#hero h2 span {
  color: #f89406;
}

#hero .btn-get-started {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.5px;
  display: inline-block;
  padding: 10px 28px;
  transition: 0.5s;
  border: 2px solid #fff;
  color: #fff;
  margin-top: 3vh;
}

#hero .btn-get-started:hover {
  background: #f89406;
  border-color: #f89406;
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (max-width: 768px) {
  #hero {
    text-align: center;
  }

  #hero .container {
    padding-top: 40px;
  }

  #hero h1 {
    font-size: 36px;
    line-height: 36px;
  }

  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }

  #hero img {
    width: 70vw;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-title {
  text-align: center;
  padding: 30px 0;
  position: relative;
}

.section-title h2 {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #191919;
  position: relative;
  z-index: 2;
}

.section-title span {
  position: absolute;
  top: 30px;
  color: #f4f4f4;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 700;
  font-size: 52px;
  text-transform: uppercase;
  line-height: 0;
}

.section-title p {
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}

@media (max-width: 575px) {
  .section-title h2 {
    font-size: 28px;
    margin-bottom: 15px;
  }

  .section-title span {
    font-size: 38px;
  }
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .content h3 {
  font-weight: 600;
  font-size: 26px;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-bottom: 10px;
}

.about .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #f89406;
}

.about .content p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.why-us {
  padding: 40px 0px;
}

.why-us .box {
  padding: 50px 30px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  transition: all ease-in-out 0.3s;
}

.why-us .box span {
  display: block;
  font-size: 28px;
  font-weight: 700;
  color: #ed5b5b;
}

.why-us .box h4 {
  font-size: 24px;
  font-weight: 600;
  padding: 0;
  color: #f89406;
  ;
}

.why-us .box p {
  color: #aaaaaa;
  font-size: 15px;
  margin: 0;
  padding: 0;
}

/* .why-us .box:hover {
  background: #f89406;
  padding: 30px 30px 70px 30px;
  box-shadow: 10px 15px 30px rgba(0, 0, 0, 0.18);
} */

/* .why-us .box:hover span,
.why-us .box:hover h4,
.why-us .box:hover p {
  color: #fff;
} */

/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
.clients {
  background: #f2f2f2;
  padding: 15px 0;
  text-align: center;
}

.clients img {
  width: 50%;
  -webkit-filter: grayscale(100);
  filter: grayscale(100);
  transition: all 0.4s ease-in-out;
  display: inline-block;
  padding: 15px 0;
}

.clients img:hover {
  -webkit-filter: none;
  filter: none;
  transform: scale(1.2);
}

@media (max-width: 768px) {
  .clients img {
    width: 40%;
  }
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
  text-align: center;
  padding: 30px 15px;
  transition: all ease-in-out 0.3s;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);

}

.services .icon-box img {
  max-width: 95%;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;

}

.events-meta {
  font-size: 14px;
  font-style: italic;
  margin: 10px 0px;
}

.events-meta i {
  color: #f89406;
  font-weight: 500;
  padding-right: 3px;
  font-size: 16px;
}

.events-meta span {
  margin: 0px 5px;
}


.services .icon-box .icon {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: #f89406;
  transition: all .3s ease-out 0s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transform-style: preserve-3d;
}

.services .icon-box .icon i {
  color: #fff;
  font-size: 28px;
}

.services .icon-box .icon::before {
  position: absolute;
  content: '';
  left: -8px;
  top: -8px;
  height: 100%;
  width: 100%;
  background: #fce5e5;
  transition: all .3s ease-out 0s;
  transform: translateZ(-1px);
}

.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
}

.services .icon-box h4 a {
  color: #191919;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

/* .services .icon-box:hover {
  background: #f89406;
  border-color: #f89406;
  padding: 30px 15px 30px 15px;
} */

.services .icon-box:hover .icon {
  background: #fff;
}

/* .services .icon-box:hover i,
.services .icon-box:hover span {
  color: white;
} */



.services .icon-box:hover .icon::before {
  background: #f3a940;
}

/* .services .icon-box:hover h4 a,
.services .icon-box:hover p {
  color: #fff;
} */

/*--------------------------------------------------------------
# Cta
--------------------------------------------------------------*/
.cta {
    padding: 120px 0;
}

.cta h3 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}

.cta p {
  color: #fff;
}

.cta .cta-btn {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 28px;
  transition: 0.5s;
  margin-top: 10px;
  border: 2px solid #fff;
  color: #fff;
}

.cta .cta-btn:hover {
  background: #f89406;
  border: 2px solid #f89406;
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 25px auto;
  list-style: none;
  text-align: center;
  border-radius: 50px;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 10px 15px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  color: #444444;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
  font-family: "Poppins", sans-serif;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  color: #f89406;
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-item {
  margin-bottom: 30px;
}

.portfolio .portfolio-item .portfolio-info {
  /* opacity: 0; */
  position: absolute;
  left: 30px;
  right: 30px;
  /* bottom: 0; */
  z-index: 3;
  transition: all ease-in-out 0.3s;
  background: rgba(255, 255, 255, 0.9);
  padding: 15px;
  bottom: 20px;
}

.portfolio .portfolio-item .portfolio-info h4 {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  color: #191919;
}

.portfolio .portfolio-item .portfolio-info p {
  color: #4c4c4c;
  font-size: 14px;
  margin-bottom: 0;
  width: 85%;
}

.portfolio .portfolio-item .portfolio-info .preview-link,
.portfolio .portfolio-item .portfolio-info .details-link {
  position: absolute;
  right: 40px;
  font-size: 30px;
  top: calc(50% - 18px);
  color: #333333;
}

/* .portfolio .portfolio-item .portfolio-info .preview-link:hover,
.portfolio .portfolio-item .portfolio-info .details-link:hover {
  color: #f89406;
} */

.portfolio .portfolio-item .portfolio-info .details-link {
  right: 18px;
}

.portfolio .portfolio-item .portfolio-links {
  opacity: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 3;
  position: absolute;
  transition: all ease-in-out 0.3s;
}

.portfolio .portfolio-item .portfolio-links a {
  color: #fff;
  margin: 0 2px;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}

/* .portfolio .portfolio-item .portfolio-links a:hover {
  color: #f89406;
} */

.portfolio .portfolio-item:hover .portfolio-info {
  cursor: pointer;
  opacity: 1;
  bottom: 30px;

}

.portfolio .portfolio-item:hover .portfolio-info .details-link {
  opacity: 1;
  bottom: 30px;
  color: #f89406;
}

/*--------------------------------------------------------------
# ministerios
--------------------------------------------------------------*/
.ministerios .box {
  padding-top: 10px;
  background: #f9f9f9;
  text-align: center;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.ministerios .box .content {
  padding: 10px;
  min-height: 35vh;
}

.ministerios .box .content .description {
  position: relative;
  height: 11.8em;
  overflow: hidden;
  /* exactly three lines */
}

.ministerios .box .content .description:after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 70%;
  height: 1.2em;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(249, 249, 249) 50%);
}

.ministerios .box img {
  width: 100%;
}

.ministerios .box h3 {
  font-weight: 400;
  padding: 15px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  color: #191919;
}

.ministerios .box h4 {
  font-size: 42px;
  color: #f89406;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 20px;
}

.ministerios .box h4 sup {
  font-size: 20px;
  top: -15px;
  left: -3px;
}

.ministerios .box h4 span {
  color: #bababa;
  font-size: 16px;
  font-weight: 300;
}

.ministerios .box ul {
  padding: 0;
  list-style: none;
  color: #191919;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
}

.ministerios .box ul li {
  padding-bottom: 16px;
}

.ministerios .box ul i {
  color: #f89406;
  font-size: 18px;
  padding-right: 4px;
}

.ministerios .box ul .na {
  color: #ccc;
  text-decoration: line-through;
}

.ministerios .box .btn-wrap {
  padding: 15px;
  text-align: center;
}

.ministerios .box .btn-buy {
  background: #f89406;
  display: inline-block;
  padding: 10px 40px;
  color: #fff;
  transition: none;
  font-size: 14px;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  transition: 0.3s;
}

.ministerios .box .btn-buy:hover {
  background: #f89406;
}

.ministerios .featured {
  background: #f89406;
  box-shadow: none;
  color: #fff;
}

.ministerios .featured h3,
.ministerios .featured h4,
.ministerios .featured ul,
.ministerios .featured ul li,
.ministerios .featured ul .na {
  color: #fff;
}

.ministerios .featured h4 span {
  color: rgba(255, 255, 255, 0.58);
}

.ministerios .featured .btn-wrap {
  padding: 15px;
  text-align: center;
}

.ministerios .featured .btn-buy {
  background: rgba(255, 255, 255, 0.2);
}

.ministerios .featured .btn-buy:hover {
  background: #fff;
  color: #f89406;
}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team .member {
  text-align: center;
  margin-bottom: 20px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  padding: 30px 20px;
  background: #fff;
}

.team .member img {
  max-width: 50%;
  border-radius: 50%;
  margin: 0 0 30px 0;
}

.team .member h4 {
  font-weight: 700;
  margin-bottom: 2px;
  font-size: 18px;
}

.team .member span {
  font-style: italic;
  display: block;
  font-size: 13px;
}

.team .member p {
  padding-top: 10px;
  font-size: 14px;
  font-style: italic;
  color: #aaaaaa;
}

.team .member .social {
  margin-top: 15px;
}

.team .member .social a {
  color: #919191;
  transition: 0.3s;
}

.team .member .social a:hover {
  color: #f89406;
}

.team .member .social i {
  font-size: 18px;
  margin: 0 2px;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 20px 0 30px 0;
}

.contact .info-box i {
  font-size: 32px;
  color: #f89406;
  border-radius: 50%;
  padding: 8px;
  border: 2px dotted #f5a0a0;
}

.contact .info-box h3 {
  font-size: 20px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
}

.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.contact .php-email-form {
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 30px;
}

.contact .php-email-form .validate {
  display: none;
  color: #f89406;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.contact .php-email-form .error-message {
  color: #fff;
  background: #ed3c0d;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br+br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input::focus,
.contact .php-email-form textarea::focus {
  background-color: #f89406;
}

.contact .php-email-form input {
  padding: 20px 15px;
}

.contact .php-email-form textarea {
  padding: 12px 15px;
}

.contact .php-email-form button[type="submit"] {
  background: #f89406;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
}

.contact .php-email-form button[type="submit"]:hover {
  background: #f89406;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  background: #efefef;
  margin-top: 98px;
}

@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 50px;
  }
}

.breadcrumbs h2 {
  font-size: 26px;
  font-weight: 600;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol li+li {
  padding-left: 10px;
}

.breadcrumbs ol li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: #333333;
  content: "/";
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}

.portfolio-details .portfolio-details-container {
  position: relative;
}

.portfolio-details .portfolio-details-carousel {
  position: relative;
  z-index: 1;
}

.portfolio-details .portfolio-details-carousel .owl-nav,
.portfolio-details .portfolio-details-carousel .owl-dots {
  margin-top: 5px;
  text-align: left;
}

.portfolio-details .portfolio-details-carousel .owl-dot {
  display: inline-block;
  margin: 0 10px 0 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ddd !important;
}

.portfolio-details .portfolio-details-carousel .owl-dot.active {
  background-color: #f89406 !important;
}

.portfolio-details .portfolio-info {
  padding: 30px;
  position: absolute;
  right: 0;
  bottom: -70px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li+li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 50px;
}

.portfolio-details .portfolio-description h2 {
  width: 50%;
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0 0 0 0;
}

@media (max-width: 768px) {
  .portfolio-details .portfolio-info {
    position: static;
    margin-top: 30px;
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: #0c0c0c;
  padding: 0 0 30px 0;
  color: #fff;
  font-size: 14px;
}

#footer .footer-top {
  padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {
  margin-bottom: 15px;
  background: #191919;
  color: #fff;
  border-top: 4px solid #f89406;
  text-align: center;
  padding: 30px 20px;
}

#footer .footer-top .footer-info h3 {
  font-size: 36px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
}

#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Raleway", sans-serif;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #262626;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #f89406;
  color: #fff;
  text-decoration: none;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #ed5b5b;
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #fff;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  color: #eb4444;
}

#footer .footer-top .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 4;
}

#footer .footer-top .footer-newsletter form input[type="email"] {
  border: 0;
  padding: 4px;
  width: calc(100% - 110px);
}

#footer .footer-top .footer-newsletter form input[type="submit"] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: #f89406;
  color: #fff;
  transition: 0.3s;
  border-radius: 4;
}

#footer .footer-top .footer-newsletter form input[type="submit"]:hover {
  background: #f89406;
}

#footer .copyright {
  border-top: 1px solid #262626;
  text-align: center;
  padding-top: 30px;
}

#footer .credits {
  padding-top: 5px;
  text-align: center;
  font-size: 13px;
  color: #fff;
}

#footer .credits a {
  color: #f89406;
}

@media (max-width: 575px) {
  #footer .footer-top .footer-info {
    margin: -20px 0 30px 0;
  }
}