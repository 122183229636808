@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Petit+Formal+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pinyon+Script&display=swap');


//Modal Video 

.modal-video {
  background-color: rgba(0, 0, 0, 0.85) !important;
}

.makeSmall {
  font-size: 8px !important;
}

.center-items {
  justify-content: center;
  align-items: center;
  padding-top: .5rem;
}


#hero h1 {
  font-family: 'Pinyon Script', cursive;
  font-size: 42px;
}


@mixin mobileDevices {
  @media screen and (min-width: 990px) {
    @content
  }
}




.navbar-base-theme {
  background-color: rgba(52, 58, 64, 0.2);
}

.navbar-dark {
  background-color: rgba(0, 0, 0, 0.9);
}

.navbar-nav .nav-link {
  color: rgba(255, 255, 255, 1) !important;

}

.navbar-nav .nav-link {
  @include mobileDevices() {
    padding: 18px 15px;
    margin: 0px 2px;
  }
}

.navbar-nav .nav-link:hover {
  background: #f89406;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>") !important;
}


.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s .5s, opacity .5s ease-in;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Overrides */
/* The heart of the matter */
.videos-group>.row {
  overflow-x: auto;
}

.videos-group>.row>.video-wrap {
  display: inline-block;
  float: none;
}

@media only screen and (min-width: 600px) {
  .videos-group>.video-wrap {
    flex-wrap: nowrap !important;
  }
}

/* Overrides Ends */